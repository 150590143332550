<template>
  <app-layout class="page__recorder-outside">
    <instructions />
    <v-text slot="text" />
    <record-shared
      @send-voice="sendHandler"
      :disabled="loading"
      :min-duration="minDuration"
      @stop="stopRecordingHandler"
      ref="record"
      :voiceKey="voiceKey"
      :route="currentRoute"
    />
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import Instructions from "./components/instructions";
import vText from "./components/text";
import RecordShared from "@/components/record";
import {
  ROUTE_READING_PASSAGE,
  ROUTE_COUNT_TO_20,
  RECORD_MIN_DURATION_RP,
} from "@/constants";
import RecordMixin from "@/mixins/record";
import RouteMixin from "@/mixins/routing";
import logVisiting from "@/mixins/logVisiting";

export default {
  name: "ReadingPassage",
  components: { AppLayout, Instructions, RecordShared, vText },
  mixins: [RecordMixin, RouteMixin, logVisiting],

  data: () => ({
    voiceKey: "audio5",
    currentRoute: ROUTE_READING_PASSAGE,
    nextRoute: ROUTE_COUNT_TO_20,
    type: "record",
    minDuration: RECORD_MIN_DURATION_RP,
  }),
};
</script>
