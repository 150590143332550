import { mapActions } from "vuex";
import { STORE_SET_PASSED } from "@/constants";

/**
 * property data should contains:
 * @param {currentRoute<string>}: passed route name like ROUTE_AUDIO_CALIBRATION
 * @param {nextRoute<string>}: next route name like ROUTE_SUSTAINED_AAH
 */
export default {
  methods: {
    ...mapActions([STORE_SET_PASSED]),

    async setPassed() {
      const { currentRoute, nextRoute: name, [STORE_SET_PASSED]: fn } = this;

      console.log(`in set passed for ${currentRoute}, name : ${name}`);

      await fn({ [currentRoute]: true });
      this.$router.push({ name }, null, () => {});
    },
  },
};
